export const MessageTypeEnum = {
  ATTENDANT: 'ATTENDANT',
  SELLER: 'SELLER',
  EXCLUDED: 'EXCLUDED',
  FINISHED: 'FINISHED',
  AUTHORIZATION_SELLER: 'AUTHORIZATION_BY_SELLER',
  UNAUTHORIZATION_SELLER: 'UNAUTHORIZATION_BY_SELLER',
  AUTHORIZATION_ATTENDANT: 'AUTHORIZATION_BY_ATTENDANT',
  UNAUTHORIZATION_ATTENDANT: 'UNAUTHORIZATION_BY_ATTENDANT',
  AUTHORIZATION_SELLER_ATTENDANT: 'AUTHORIZATION_SELLER_BY_ATTENDANT',
  UNAUTHORIZATION_SELLER_ATTENDANT: 'UNAUTHORIZATION_SELLER_BY_ATTENDANT',
  UNSELECTED: 'UNSELECTED',

};

export default MessageTypeEnum;
