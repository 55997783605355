<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController
      @formSubbmit="formSubbmit"
      :formModified="formModified"
      :form="form"
      :editing="editing"
      :disabled="false"
      :loading="loading"
      :steps="steps"
      backAlertMessage="Deseja cancelar o cadastro deste atendimento?"
      alertScreenTitle="cadastro de atendimento"
      :title="!editing ? 'Cadastro de atendimento' : 'Edição de atendimento'"
      :disabledTabCount="editing ? 0 : 1"
      :showCustomButton="true"
      customButtonLabel="Finalizar Atendimento"
      :customButtonDisabled="this.form.status === 'Concluído'"
      @customAction="showfinishTicketModal"
      :showDeleteActionButton="false"
      :isSaveNextTab="false"
      :actualTab="actualTab"
    >
      <GeneralDataProductForm
        title="Informações Gerais"
        :form="form"
        :loading="loading"
        :validationRules="steps['GeneralInformationForm'].rules"
        @validateRequired="validateRequired"
        @selectedTopic="selectedTopic"
      />
      <AttendanceForm title="Atendimento" :form="form" :loading="loading" :validationRules="steps['AttendanceForm'].rules" :disabled="!editing" />
    </FormStepController>
    <ConfirmDialog
      @confirm="confirmFinishTicket"
      @close="finishTicketModal = false"
      confirmActionLabel="Sim, finalizar agora"
      dialogType="danger"
      :item="form"
      :show-form="finishTicketModal"
    >
      <template v-slot:message>
        <p>Este atendimento será finalizado</p>
        <p>com esta ação.</p>
        <br />
        <p>Deseja mesmo finalizar o</p>
        <p>atendimento?</p>
        <br />
      </template>
    </ConfirmDialog>
  </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import GeneralDataProductForm from './steps/GeneralInformationForm';
import AttendanceForm from './steps/AttendanceForm';
import FormStepMixin from '../../../components/template/form/mixins/FormStepMixin';
import { finishTicket } from '@/service/ticket/tickets-service';
import { get, post, put } from '@/service/api';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';
import ConfirmDialog from '@/components/utils/dialog/ConfirmDialog';
import { ChannelOptions } from '@/static-data/ChannelOptions';
import { getSeller } from '@/service/seller/seller-service';

export default {
  name: 'AdminForm',
  mixins: [FormStepMixin],
  data() {
    return {
      editing: false,
      loading: true,
      saveDialogAlert: false,
      finishTicketModal: false,
      formModified: false,
      actualTab: 0,
      steps: {
        GeneralInformationForm: {
          step: this.editing ? 2 : 1,
          status: {},
          disabled: false,
          required: {
            seller: true,
            topic: true,
            orderId: false,
            attendanceChannel: true,
            attendant: true,
            description: true,
          },
          rules: {
            seller: (value) => !!value || 'Campo `Seller` é Obrigatório.',
            topic: (value) => !!value || 'Campo `Assunto` é Obrigatório.',
            attendant: (value) => !!value || 'Campo `Atendente` é Obrigatório.',
            attendanceChannel: (value) => !!value || 'Campo `Canal de atendimento` é Obrigatório.',
            description: (value) => {
              if (value && value.length > 5000) {
                return 'Texto muito extenso';
              }
              return !!value || 'Campo `Descrição` é Obrigatório.';
            },
            orderId: () => {
              if (this.form?.topic && this.form?.topic.orderInfoRequired && !this.form.orderId) {
                return 'Campo `Pedido` é Obrigatório.';
              }
              if (this.form?.orderId && !this.form.order?.clientName) {
                return 'Pedido não encontrado';
              }
              if (this.form?.orderId && !this.isValidOrder(this.form?.orderId)) {
                return 'Pedido inválido';
              }
              return true;
            },
          },
        },
        AttendanceForm: {
          step: this.editing ? 1 : 2,
          status: {},
          required: {},
          disabled: !this.editing,
        },
      },
      form: {
        seller: {},
        attendant: {},
        attendanceChannel: null,
        attachments: [],
        requester: null,
        status: 'Aguardando atendimento',
        topic: null,
        orderId: null,
        ourNumber: null,
        description: null,
        messages: [],
        order: null,
      },
    };
  },
  components: {
    AttendanceForm,
    GeneralDataProductForm,
    FormStepController,
    ConfirmDialog,
  },
  async mounted() {
    try {
      this.loading = true;
      await this.init();
      await this.initOrderAttendance();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async update(data) {
      this.saveDialogAlert = false;

      if (data.topic?.id) {
        data.topic = data.topic.id;
      }
      if (data.attendanceChannel) {
        // eslint-disable-next-line prefer-destructuring
        data.attendanceChannel = ChannelOptions.filter((value) => value.value === data.attendanceChannel)[0];
      }
      if (data.seller?.id) {
        data.seller = data.seller.id;
      }
      if (data.attendant?.username) {
        data.attendant = data.attendant.username;
      }
      try {
        const response = await put(`ui-integrator/tickets/${this.$route.params.id}`, data);
        if (response.status >= 200 && response.status <= 300) {
          notification('Atendimento atualizado com sucesso.', 'success');
        } else if (response.data?.cause) {
          notification(JSON.parse(response.data.cause.split(']:')[1])[0].message, 'error');
        } else {
          notification(`${response.response.data.message}`, 'error');
        }
      } catch (e) {
        notification('Erro ao atualizar atendimento', 'error');
      }
    },
    async save(data) {
      try {
        if (data.attendant?.username) {
          data.attendant = data.attendant.username;
        }
        data.topic = data.topic.id;
        const response = await post('ui-integrator/tickets/create', data, {
          headers: {},
        });
        if (response.status >= 200 && response.status <= 300) {
          this.editing = true;
          this.steps.AttendanceForm.disabled = false;
          await this.mountForm(response.data);
          notification('Chamado criado com sucesso.', 'success');
          routerPush(`/attendance/forms/edit/${response.data.id}`);
          this.actualTab = 1;
        } else if (response.data?.cause) {
          notification(JSON.parse(response.data.cause.split(']:')[1])[0].message, 'error');
        } else {
          notification(`${response.response.data.message}`, 'error');
        }
      } catch (e) {
        notification('Erro ao criar atendimento', 'error');
      }
    },
    async mountForm(data) {
      this.form = data;
      if (data.sellerResponse) {
        const seller = await getSeller(data.sellerResponse.id);
        this.form.seller = seller.data;
      }
      if (data.attendantResponse) {
        this.form.attendant = { username: data.attendantResponse.username };
      }
      if (data.attendanceChannel) {
        // eslint-disable-next-line prefer-destructuring
        data.attendanceChannel = ChannelOptions.filter((value) => value.value === data.attendanceChannel)[0];
      }
    },
    async showfinishTicketModal() {
      this.finishTicketModal = true;
    },
    async formSubbmit() {
      const data = { ...this.form };
      if (this.editing) {
        await this.update(data);
      } else {
        await this.save(data);
      }
    },
    dialogDenial() {
      this.saveDialogAlert = false;
    },
    dialogConfirmation() {
      this.save();
    },
    async init() {
      this.loading = true;
      if (!this.$route.params.id) return;
      this.editing = true;
      this.actualTab = 1;
      const { data } = await get(`help-assistance-management/tickets/${this.$route.params.id}`);
      this.form = data;
      if (data.sellerResponse) {
        const seller = await getSeller(data.sellerResponse.id);
        this.form.seller = seller.data;
      }
      if (data.attendantResponse) {
        this.form.attendant = { username: data.attendantResponse.username };
      }
      if (data.attendanceChannel) {
        // eslint-disable-next-line prefer-destructuring
        data.attendanceChannel = ChannelOptions.filter((value) => value.value === data.attendanceChannel)[0];
      }
      this.steps.GeneralInformationForm.required.orderId = this.form.orderId || data.topic.orderInfoRequired;
      this.$forceUpdate();
      this.loading = false;
    },
    initOrderAttendance() {
      const orderId = this.$route.params?.orderId;
      if (!orderId) return;
      this.form.orderId = orderId;
    },
    selectedTopic(topic) {
      this.steps.GeneralInformationForm.required.orderId = topic?.orderInfoRequired;
    },
    async confirmFinishTicket() {
      try {
        const response = await finishTicket(this.$route.params.id);
        if (response.status >= 200 && response.status <= 300) {
          notification('Atendimento atualizado com sucesso.');
          routerPush('/attendance');
        } else {
          notification(`Erro ao finalizar atendimento: ${response.response.data.message}`, 'error');
        }
      } catch (e) {
        notification(`Erro ao finalizar atendimento: ${e}`, 'error');
      }
    },
    isValidOrder(orderId) {
      const regex = /^[a-z]\d{8}-\d{2}$/;
      return regex.test(orderId);
    },
  },
};
</script>

<style scoped></style>
