<template>
  <div class="d-flex pt-1">
    <div class="name-box mr-5" :style="[isSellerView ? { 'color': '#07bee7' } : '']">
      <p>...</p>
    </div>
      <v-alert class="message-box" :color="isSellerView ? '#D5F8FF' : ''">
            <strong style="color: #07bee7;">
              Aguardando resposta
            </strong>
      </v-alert>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'FinalMessage',
  props: {
    isSellerView: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.message-box {
  text-align: justify;
  width: auto;
  flex-grow: 1;
}

.name-box {
  width: 15%;
}

</style>
