<template>
  <div>
    <AdminForm v-if="$store.getters.isAdmin"></AdminForm>
    <SellerForm v-else></SellerForm>
  </div>
</template>

<script>
import AdminForm from './admin/AdminForm';
import SellerForm from './seller/SellerForm';

export default {
  name: 'Form',
  components: { SellerForm, AdminForm },
};
</script>

<style scoped>

</style>
