<template>
  <v-skeleton-loader
    :loading="loading"
    type="article, actions"
  >
  <FormContent :selected='selected' class='pa-6'>
      <div class="custom-font-title mb-8">Detalhes</div>
    <div>
        <TextFieldInput
          v-model='form.requester'
          :disabled="!!form.id"
          label='Solicitante'
          placeHolder='Informe o nome de quem solicitou o chamado se houver'
          input-type='text'
          labelWidth='20%'
        />
    </div>
    <v-row class='margin-fix pa-3'>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <Select2Input
          item-text="title"
          @focusout="validate('topic')"
          @input="selectedTopic"
          aria-placeholder='Selecione um assunto'
          item-value="id"
          label-width="40%"
          v-model="form.topic"
          :disabled="!!form.id"
          label='Assunto*'
          :multiple="false"
          :search="searchTopics">
        </Select2Input>
      </v-col>
      <v-col xl="6" lg="6" sm="12" md="6" class='pb-0'>
        <TextFieldInput
          paddingLeft="5%" label-width="20%"
          v-model='form.orderId'
          :disabled="!!form.id"
          @input="getOrderId()"
          @focusout="validate('orderId')"
          :label="orderText"
          :error="orderNotFinded"
          :message="orderValidation"
          placeHolder='Digite'
          input-type='text'
          labelWidth='40%'/>
      </v-col>
    </v-row>
    <div  class="d-flex my-8" v-if="form.orderId && order.clientName" style="padding-bottom: 2%">
      <div class='left-content order-label'>
        Sobre o pedido
      </div>
      <div class="d-flex right-content order-box justify-space-around pa-3">
        <div>
        <div>
          <label class="order-font-label">Data e Hora: </label>
          <span class="order-font-data">{{ new Date(order.submittedAt).toLocaleString() }}</span>
        </div>
        <div>
          <label class="order-font-label">Valor: </label>
          <span class="order-font-data"> {{ order.total.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</span>
        </div>
      </div>
        <div>
          <label class="order-font-label">Cliente: </label>
          <span class="order-font-data"> {{ order.clientName }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="custom-font-title mb-8">Conteúdo inicial</div>
      <div class="custom-font mb-7 ">
        Para a resposta ser assertiva, detalhe o motivo do seu chamado.
        </div>
      <v-form ref="card" v-model="card" class="d-flex">
        <label class="left-content">Motivo*</label>
        <v-textarea
        @focusout="validate('description')"
          class="right-content"
          placeholder="Digite"
          v-model="form.description"
          :rules="[rules.required, rules.length(5000)]"
          counter="5000"
          :disabled="!!form.id"
          filled
          dense
          light
        />
      </v-form>
    <Attachments :form="form" @uploadFile="uploadFile" id="attachmentsTicket" :uploadDisabled="!!form.id" :isLoading="isLoading"/>
  </div>
  </FormContent>
  </v-skeleton-loader>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import Select2Input from '../../../../components/template/form/input/Select2Input';
import TextFieldInput from '../../../../components/template/form/input/TextFieldInput';
import { ChannelOptions } from '@/static-data/ChannelOptions';
import getAllTopics from '@/service/topics/topics-service';
import { getOrderByNumber } from '@/service/order/order-service';
import { get, post } from '@/service/api';
import Attachments from '@/components/template/Attachments';
import notification from '@/service/notification';

export default {
  name: 'GeneralInformationForm',
  components: {
    Select2Input,
    Attachments,
    TextFieldInput,
    FormContent,
  },
  data: () => ({
    card: false,
    rules: {
      length: (len) => (v) => (v || '').length <= len || `Você excedeu o limite de caracteres disponíveis na mensagem ${len}`,
      required: (v) => !!v || 'Campo obrigatório',
    },
    order: {},
    selected: true,
    isLoading: false,
    orderNotFinded: false,
    search: null,
    attedanceChanelOptions: ChannelOptions,
    topics: [],
  }),
  props: {
    title: {
      type: String,
      required: false,
      default: 'Geral',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
      this.initOrder();
  },
  computed: {
    orderText() {
      return `Pedido${this.form?.topic && this.form?.topic?.orderInfoRequired ? '*' : ''}`;
    },
    orderValidation() {
      if (this.form?.topic && this.form?.topic.orderInfoRequired && !this.form.orderId) return 'Campo `Pedido` é Obrigatório.';
      if (this.form?.orderId && !this.order?.clientName && this.orderNotFinded) return 'Pedido não encontrado';
      if (this.form?.orderId && !this.isValidOrder(this.form?.orderId)) return 'Pedido inválido';
      return '';
    },
  },
  methods: {
    async initOrder() {
        try {
            const orderId = this.$route.params?.orderId;
            if (orderId && this.isValidOrder(orderId)) {
                this.isLoading = true;
                this.form.orderId = orderId;
                await this.getOrderId();
                this.isLoading = false;
            } else {
              const currentUser = JSON.parse(localStorage.getItem('current_user'));
              this.form.requester = currentUser.name;
            }
        } catch (error) {
            this.isLoading = false;
            console.error('Error on initOrder', error);
        }
    },
    async getOrderId() {
      this.orderNotFinded = false;
      this.order = { clientName: '' };
      if (this.form.orderId && this.isValidOrder(this.form.orderId)) {
        const data = await getOrderByNumber(this.form.orderId);
        if (data.status === 200) {
          this.order = data.data;
          this.form.order = data.data;
        } else {
          this.orderNotFinded = true;
          this.order = {};
          this.form.order = null;
        }
      }
    },
    validate(field) {
      this.$emit('validateRequired', this.$options.name, field, this.form[field]);
    },
    async searchSellers() {
      const currentUser = JSON.parse(localStorage.getItem('current_user'));
      const seller = currentUser?.sellers;
      const { data } = await get(`ui-integrator/sellers?sellers=${seller.toString()}`, {
        headers: {
          sellerId: currentUser?.sellerId,
        },
      });
      return data;
    },
    async searchUsers() {
      const { data } = await get('/auth/user/fetch');
      return data.register;
    },
    async searchTopics() {
      const data = await getAllTopics();
      this.topics = data;
      return data;
    },
    selectedTopic(topicId) {
      this.form.topic = this.topics.find((topic) => topic.id === topicId);
      if (!!this.form.order && !!this.form.orderId && this.form.topic?.orderInfoRequired) {
        this.$emit('selectedTopic', this.form.topic);
        this.validate('orderId');
      } else {
        this.$emit('selectedTopic', this.form.topic);
      }
    },
    async uploadFile() {
      this.isLoading = true;
      const file = document.getElementById('attachmentsTicket').files[0];
      const formData = new FormData();

      if (file) {
        formData.append('file', file, file.name);
      }

      // eslint-disable-next-line no-await-in-loop
      post('upload-service/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response.status === 200) {
          const attachment = {
            key: response.data.id,
            storageKey: response.data.id,
            name: response.data.fileName,
            url: response.data.folder + response.data.id,
          };
          this.form.attachments.push(attachment);
        } else {
          notification(`Erro ao anexar o arquivo: ${response.response.data.message}`, 'error');
        }
      }).catch((error) => {
        notification('Erro ao anexar o arquivo', 'error');
      }).finally(() => {
        this.isLoading = false;
      });
    },
    isValidOrder(orderId) {
      const regex = /^[a-z]\d{8}-\d{2}$/;
      return regex.test(orderId);
    },
  },
};
</script>

<style>
.v-text-field .v-input__slot {
  height: auto;
}

.v-input__icon--prepend {
  display: none;
}

.order-box {
    background: 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
}
</style>
