<template>
  <div class="d-flex flex-column">
      <div class='d-flex flex-gow-1 mb-6'>
        <div class='left-content'>
          Anexos
        </div>
        <div class='right-content'>
          <label class="attachments-label">
            <span>
              <input @input="uploadFile($event)" type="file" :id="id" name="myfile">
            </span>
          </label>
          <v-btn :disabled="uploadDisabled" outlined  @click="upload()" class="d-flex custom-button--light" >
            Upload
            <v-icon v-show="!$props.isLoading" style="font-size: 20px;">mdi-upload</v-icon>
            <v-progress-circular v-show="$props.isLoading"
                                :size="20"
                                class="ml-1"
                                color="primary"
                                indeterminate />
          </v-btn>
        </div>
      </div>
      <div class="mb-6">
        <div class='font-label attachment-description'>

        </div>
        <v-simple-table style="width: 90%">
          <template v-slot:default>
            <thead>
            </thead>
            <tbody>
            <tr style="width: 100%" v-for="attachment in form.attachments" :key="attachment.id">
              <td>{{ attachment.name }}</td>
              <td>
                <a @click.prevent="download(attachment)" style="color: white; float: right;">
                  <v-icon style="font-size: 20px; width: auto" class="mr-3">mdi-download</v-icon>
                </a>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Attachments',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
    },
    uploadDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    uploadFile(event) {
      this.$emit('uploadFile', event);
    },
    upload() {
      const elementById = document.getElementById(this.id);
      elementById.click();
    },
    async download(file) {
      window.open(`${process.env.VUE_APP_URL_API}/upload-service/file/download/${file.storageKey}`, '_parent');
    },
  },
};
</script>

<style>
.attachment-description {
  width: 10%;
  vertical-align: middle;
  text-align: left;
}

.attachments-label{
  display: none;
}

</style>
