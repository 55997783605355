<template>
  <v-timeline align-top dense>
    <v-timeline-item
      v-for="(message, index) in messages"
      :key="message.id"
      :color="getColor(message)"
      :icon="getIcon(message, index)">
      <ChatMessage :deleteMessageProp="deleteMessageProp" :deleteAttachmentProp="deleteAttachmentProp"
                   :message="message"
                   :isAllowedDeleteMessage="isAllowedDeleteMessage"/>
    </v-timeline-item>

    <v-timeline-item v-if="!finished" :color="isSellerView ? '#07bee7' : '#6c757d'">
      <FinalMessage :isSellerView="isSellerView"/>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import Vue from 'vue';
import ChatMessage from '@/components/template/chat/ChatMessage';
import FinalMessage from '@/components/template/chat/FinalMessage';
import { MessageTypeEnum } from '@/enums/MessageTypeEnum';

export default Vue.extend({
  name: 'TimelineChat',
  components: {
    ChatMessage,
    FinalMessage,
  },
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    finished: {
      type: Boolean,
      default: false,
    },
    isSellerView: {
      type: Boolean,
      default: false,
    },
    isAllowedDeleteMessage: {
      type: Boolean,
      default: false,
    },
    deleteMessageProp: {
      type: Function,
    },
    deleteAttachmentProp: {
      type: Function,
    },
  },
  methods: {
    getIcon(message, index) {
      if (index === 0) {
        return 'mdi-star';
      }

      return message.type === MessageTypeEnum.FINISHED ? 'mdi-check' : '';
    },
    getColor(message) {
      switch (message.type) {
        case MessageTypeEnum.ATTENDANT:
          return '#07bee7';
        case MessageTypeEnum.FINISHED:
          return '#02d13f';
        default:
          return '#6C757D';
      }
    },
  },
});
</script>

<style scoped>
.v-timeline::before {
  top: 55px;
  height: calc(100% - 120px)
}
</style>
