<template>
    <v-skeleton-loader :loading="loading" type="article, actions">
        <FormContent :selected="selected" class="pa-6">
            <div v-if="form.id">
                <v-row style="padding-bottom: 0px !important;" class="margin-fix pa-3 pb-0">
                    <h3 class="subtext subtext_form_devide_h4">Dados do chamado</h3>
                </v-row>
                <v-row class="margin-fix pa-3">
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <TextFieldInput
                            v-model="form.id"
                            :disabled="true"
                            label="Número do chamado"
                            input-type="text"
                            labelWidth="40%"
                            labelHAlign="20%"
                        />
                    </v-col>
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <TextFieldInput
                            paddingLeft="5%"
                            :disabled="true"
                            v-model="form.status"
                            label="Status"
                            input-type="text"
                            labelWidth="20%"
                            labelHAlign="20%"
                        />
                    </v-col>
                </v-row>
                <v-row class="margin-fix pa-3">
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <TextFieldInput
                            v-model="openDate"
                            :disabled="true"
                            label="Data da abertura"
                            input-type="text"
                            labelWidth="40%"
                            labelHAlign="20%"
                        />
                    </v-col>
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <TextFieldInput
                            paddingLeft="5%"
                            :disabled="true"
                            v-model="openTime"
                            label="Hora da abertura"
                            input-type="text"
                            labelWidth="20%"
                            labelHAlign="20%"
                        />
                    </v-col>
                </v-row>
            </div>
            <v-row style="padding-bottom: 0px !important;" class="margin-fix pa-3 pb-0">
                <h3 class="subtext subtext_form_devide_h4">Detalhes</h3>
            </v-row>
            <v-row class="margin-fix pa-3">
                <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
                    {{ form.seller ? '' : '' }}
                    <Select2Input
                        item-text="companyName"
                        item-value="id"
                        label-width="20%"
                        v-model="form.seller"
                        aria-placeholder="Selecione o nome do Seller contactado"
                        label="Seller*"
                        @focusout="validate('seller')"
                        :multiple="false"
                        :search="searchSellers"
                        :disabled="!!form.id"
                    ></Select2Input>
                </v-col>
            </v-row>
            <v-row class="margin-fix pa-3">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <TextFieldInput
                        v-model="form.requester"
                        label="Solicitante"
                        placeHolder="Nome de quem solicitou se houver"
                        input-type="text"
                        :disabled="!!form.id"
                        labelWidth="40%"
                        labelHAlign="20%"
                    />
                </v-col>
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <ComboboxInput
                        paddingLeft="5%"
                        label-width="20%"
                        v-model="form.attendanceChannel"
                        persistent-hint
                        :disabled="!!form.id"
                        label="Canal de atendimento*"
                        placeHolder="Selecione ou digite"
                        label-value="label"
                        item-value="value"
                        labelWidth="40%"
                        @focusout="validate('attendanceChannel')"
                        :options="attedanceChanelOptions"
                    />
                </v-col>
            </v-row>

            <v-row class="margin-fix pa-3">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <Select2Input
                        item-text="title"
                        @focusout="validate('topic')"
                        @input="selectedTopic"
                        item-value="id"
                        label-width="40%"
                        v-model="form.topic"
                        :disabled="!!form.id"
                        label="Assunto*"
                        :multiple="false"
                        :search="searchTopics"
                    >
                    </Select2Input>
                </v-col>
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <TextFieldInput
                        paddingLeft="5%"
                        label-width="20%"
                        v-model="form.orderId"
                        @input="getOrderId()"
                        @focusout="validate('orderId')"
                        :label="orderText"
                        :disabled="!!form.id"
                        placeHolder="Digite"
                        :error="orderNotFinded"
                        :message="orderValidation"
                        input-type="text"
                        labelWidth="40%"
                    />
                </v-col>
            </v-row>
            <v-row v-if="form.orderId && order.clientName" style="padding-bottom: 2%">
                <div class="order-label">
                    Sobre o pedido
                </div>
                <v-row class="order-box pa-3">
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <label class="order-font-label">Data e Hora : &ensp; </label>
                        <span class="order-font-data">{{ new Date(order.submittedAt).toLocaleString() }}</span>
                    </v-col>
                    <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                        <label class="order-font-label">Valor : &ensp; </label
                        ><span class="order-font-data"> {{ order.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                    </v-col>
                    <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
                        <label class="order-font-label">Cliente : &ensp; </label><span class="order-font-data"> {{ order.clientName }}</span>
                    </v-col>
                </v-row>
            </v-row>
            <v-row class="margin-fix pa-3">
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <Select2Input
                        item-text="username"
                        @focusout="validate('attendant')"
                        item-value="username"
                        label-width="40%"
                        v-model="form.attendant"
                        label="Atendente SAK*"
                        :multiple="false"
                        :search="searchUsers"
                        :disabled="!!form.id"
                    ></Select2Input>
                </v-col>
                <v-col xl="6" lg="6" sm="12" md="6" class="pb-0">
                    <TextFieldInput
                        paddingLeft="5%"
                        label-width="20%"
                        v-model="form.ourNumber"
                        label="Nosso número"
                        placeHolder="Digite se necessário"
                        input-type="text"
                        labelWidth="40%"
                        :disabled="!!form.id"
                    />
                </v-col>
            </v-row>
            <v-row style="padding-bottom: 0px !important;" class="margin-fix pa-3 pb-0">
                <h3 class="subtext subtext_form_devide_h4">Conteúdo inicial</h3>
            </v-row>
            <v-row class="margin-fix pa-3 pb-0" style="padding-top: 0px !important;">
                <p style=" width: 100% !important;" class="subtext subtext_form_devide_h5">
                    <br />Para a resposta ser assertiva, detalhe o motivo do seu chamado
                </p>
            </v-row>
            <v-row class="margin-fix pa-3">
                <v-col xl="12" lg="12" sm="12" md="12" class="pb-0">
                    <TextAreaInput
                        v-model="form.description"
                        label="Motivo*"
                        :disabled="!!form.id"
                        @focusout="validate('description')"
                        :validationRules="[validationRules.description]"
                        placeHolder="Digite"
                        :maxLength="5000"
                        labelWidth="10%"
                    />
                </v-col>
            </v-row>

            <Attachments :form="form" @uploadFile="uploadFile" id="attachmentsTicket" :uploadDisabled="!!form.id" :isLoading="isLoading" />
        </FormContent>
    </v-skeleton-loader>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import Select2Input from '../../../../components/template/form/input/Select2Input';
import TextFieldInput from '../../../../components/template/form/input/TextFieldInput';
import ComboboxInput from '../../../../components/template/form/input/ComboboxInput';
import TextAreaInput from '../../../../components/template/form/input/TextAreaInput';
import { getOrderByNumber } from '@/service/order/order-service';
import getAllTopics from '@/service/topics/topics-service';
import { get, post } from '@/service/api';
import { ChannelOptions } from '@/static-data/ChannelOptions';
import Attachments from '@/components/template/Attachments';
import notification from '@/service/notification';

export default {
    name: 'GeneralInformationForm',
    components: {
        Select2Input,
        ComboboxInput,
        TextFieldInput,
        TextAreaInput,
        FormContent,
        Attachments,
    },
    data: () => ({
        selected: false,
        order: {},
        isLoading: false,
        orderNotFinded: false,
        search: null,
        attedanceChanelOptions: ChannelOptions,
        topics: [],
        sellersData: null,
        initializedSellers: false,
    }),
    props: {
        title: {
            type: String,
            required: false,
            default: 'Geral',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        form: {
            type: Object,
            default: () => ({}),
        },
        validationRules: {
            type: Object,
            default: () => ({}),
        },
    },
    created() {
        this.initOrder();
    },
    watch: {
        sellersData: {
            handler(newValue) {
                if (this.initializedSellers) return;
                this.initializedSellers = true;
                this.initSeller(newValue);
            },
        },
    },
    computed: {
        orderText() {
            return `Pedido${this.form?.topic && this.form?.topic?.orderInfoRequired ? '*' : ''}`;
        },
        openTime() {
            return this.form.id ? new Date(this.form.createdAt).toLocaleTimeString() : null;
        },
        openDate() {
            return this.form.id ? new Date(this.form.createdAt).toLocaleDateString() : null;
        },
        orderValidation() {
            if (this.form?.topic && this.form?.topic.orderInfoRequired && !this.form.orderId) return 'Campo `Pedido` é Obrigatório.';
            if (this.form?.orderId && !this.order?.clientName && this.orderNotFinded) return 'Pedido não encontrado';
            if (this.form?.orderId && !this.isValidOrder(this.form?.orderId)) return 'Pedido inválido';
            return '';
        },
    },
    methods: {
        async initSeller(sellersData) {
            try {
                if (!sellersData || sellersData.length <= 0) return;
                const sellerId = localStorage.getItem('sellerId');
                const { id } = sellersData?.find((seller) => seller.id === sellerId);
                this.form.seller = id;
                this.validate('seller');
            } catch (error) {
                console.error('Error on initSeller', error);
            }
        },
        async initOrder() {
            try {
                const orderId = this.$route.params?.orderId;
                if (orderId && this.isValidOrder(orderId)) {
                    this.isLoading = true;
                    this.form.orderId = orderId;
                    await this.getOrderId();
                    this.isLoading = false;
                } else {
                    const currentUser = JSON.parse(localStorage.getItem('current_user'));
                    this.form.attendant = { username: currentUser.username };
                    this.form.requester = currentUser.name;
                    this.validate('attendant');
                }
            } catch (error) {
                this.isLoading = false;
                console.error('Error on initOrder', error);
            }
        },
        async getOrderId() {
            this.orderNotFinded = false;
            this.order = {};
            if (this.form.orderId && this.isValidOrder(this.form.orderId)) {
                const data = await getOrderByNumber(this.form.orderId);
                if (data.status === 200) {
                  this.order = data.data;
                  this.form.order = data.data;
                } else {
                  this.orderNotFinded = true;
                  this.order = {};
                  this.form.order = null;
                }
            }
        },
        async uploadFile() {
            this.isLoading = true;
            const file = document.getElementById('attachmentsTicket').files[0];
            const formData = new FormData();

            if (file) {
                formData.append('file', file, file.name);
            }

            // eslint-disable-next-line no-await-in-loop
            post('upload-service/file/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((response) => {
                  if (response.status === 200) {
                    const attachement = {
                      key: response.data.id,
                      storageKey: response.data.id,
                      name: response.data.fileName,
                      url: response.data.folder + response.data.id,
                    };
                    this.form.attachments.push(attachement);
                  } else {
                    notification(`Erro ao anexar o arquivo: ${response.response.data.message}`, 'error');
                  }
                })
                .catch((error) => {
                    notification('Erro ao anexar o arquivo', 'error');
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        validate(field) {
            this.$emit('validateRequired', this.$options.name, field, this.form[field]);
        },
        async searchSellers() {
            const { data } = await get('ui-integrator/sellers/fetch');
            this.sellersData = data?.register;
            return data.register;
        },
        async searchUsers() {
            const { data } = await get('/auth/user/fetch');
            return data.register;
        },
        async searchTopics() {
            const data = await getAllTopics();
            this.topics = data;
            return data;
        },
        selectedTopic(topicId) {
            this.form.topic = this.topics.find((topic) => topic.id === topicId);
            if (!!this.form.order && !!this.form.orderId && this.form.topic?.orderInfoRequired) {
              this.$emit('selectedTopic', this.form.topic);
              this.validate('orderId');
            } else {
              this.$emit('selectedTopic', this.form.topic);
            }
        },
        isValidOrder(orderId) {
            const regex = /^[a-z]\d{8}-\d{2}$/;
            return regex.test(orderId);
        },
    },
};
</script>

<style>
.order-font-data {
    text-align: left;
    color: #6c757d;
    opacity: 1;
}

.order-font-label {
    letter-spacing: 0px;
    color: #6c757d;
    opacity: 1;
}

.order-box {
    padding: 20px;
    margin-right: 1%;
    background: 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
}

@media only screen and (max-width: 1250px) {
    .order-label {
        display: none;
    }
}

@media only screen and (min-width: 1250px) {
    .order-label {
        width: 20.5%;
    }
}

.v-text-field .v-input__slot {
    height: auto;
}

.v-input__icon--prepend {
    display: none;
}

label span input {
    z-index: 999;
    line-height: 0;
    font-size: 50px;
    position: absolute;
    top: -2px;
    left: -700px;
    opacity: 0;
    filter: alpha(opacity=0);
    -ms-filter: 'alpha(opacity=0)';
    cursor: pointer;
    _cursor: hand;
    margin: 0;
    padding: 0;
}
</style>
