<template>
  <div class="d-flex ">
    <div class="name-box mr-5" :style="`color: ${getUserInfoColor()}`">
      <span v-show="isAttendant">SAK-</span>
      {{ message.username }}
      <div class="box--book">{{ date }}</div>
      <div class="box--book">{{ time }}</div>
    </div>
      <v-alert :color="getMessageColor()" class="message-box">
        <p class="p3" v-show="message.privateObservation">Observação privada:</p>
        <p class="p3 authorization" v-show="ifAuthorizationSeller">Atendimento autorizado em nome do seller.</p>
        <p class="p3 unauthorization" v-show="ifUnauthorizationSeller">Atendimento não autorizado em nome do seller.</p>
        <p class="p3 authorization" v-show="ifAuthorization">Atendimento autorizado.</p>
        <p class="p3 unauthorization" v-show="ifUnauthorization">Atendimento não autorizado.</p>

        <div v-if="isExcluded">
          <v-icon class="mr-3 color--orange-0">mdi-cancel</v-icon>
          <span class="box--info color--orange-0">{{ message.text }}</span>
        </div>

        <div v-else :class="`message-box ${allowedDeleteMessage() ? 'pr-10' : ''}`">
          <span class="box--info" v-if="!ifFinished">{{ message.text }}</span>
          <span v-else class="finished-message">{{ message.text }}</span>
        </div>

        <div v-if="!isExcluded">
          <div class="attachment" v-for="(attachment, index) in message.attachments" :key="attachment.id">
            <div v-if="attachment.isExcluded">
              <v-icon class="mr-3 font-color">mdi-cancel</v-icon>
              <span class="Text--info font-color">{{ attachment.name }}</span>
            </div>

            <div v-else>
              <span class="text--info">
                {{ attachment.name }}
              </span>

              <a @click.prevent="download(attachment)" class="layout-button">
                <v-icon class="mr-3" color="primary">mdi-download</v-icon>
              </a>

              <a v-if="allowedPermissionDelete()" @click.prevent="() => deleteAttachment(attachment, index)" class="layout-button">
                <v-icon class="mr-3" color="primary" >mdi-delete</v-icon>
              </a>
            </div>
          </div>
        </div>
        <a v-if="allowedDeleteMessage()" class="delete-button" @click.prevent="deleteMessage()">
          <v-icon class="mr-3">mdi-delete</v-icon>
        </a>
      </v-alert>
</div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { MessageTypeEnum } from '@/enums/MessageTypeEnum';
import isAdmin from '@/utils/user-utils';

export default Vue.extend({
  name: 'ChatMessage',
  props: {
    message: {
      type: Object,
      default: () => ({}),
    },
    isAllowedDeleteMessage: {
      type: Boolean,
      default: false,
    },
    deleteMessageProp: {
      type: Function,
    },
    deleteAttachmentProp: {
      type: Function,
    },
  },
  computed: {
    date() {
      return moment(new Date(this.message.createdAt), 'MM/DD/YYYY').format('DD/MM/yyyy');
    },
    time() {
      return new Date(this.message.createdAt).toLocaleTimeString('pt-BR');
    },
    isAttendant() {
      return this.message.type === MessageTypeEnum.ATTENDANT;
    },
    ifFinished() {
      return this.message.type === MessageTypeEnum.FINISHED;
    },
    isExcluded() {
      return this.message.type === MessageTypeEnum.EXCLUDED;
    },
    ifAuthorization() {
      return this.message.type === MessageTypeEnum.AUTHORIZATION_SELLER
      || this.message.type === MessageTypeEnum.AUTHORIZATION_ATTENDANT;
    },
    ifUnauthorization() {
      return this.message.type === MessageTypeEnum.UNAUTHORIZATION_SELLER
      || this.message.type === MessageTypeEnum.UNAUTHORIZATION_ATTENDANT;
    },
    ifAuthorizationSeller() {
      return this.message.type === MessageTypeEnum.AUTHORIZATION_SELLER_ATTENDANT;
    },
    ifUnauthorizationSeller() {
      return this.message.type === MessageTypeEnum.UNAUTHORIZATION_SELLER_ATTENDANT;
    },
  },
  methods: {
    async download(file) {
      window.open(`${process.env.VUE_APP_URL_API}/upload-service/file/download/${file.storageKey}`, '_parent');
    },
    getUserInfoColor() {
      switch (this.message.type) {
        case MessageTypeEnum.ATTENDANT:
        case MessageTypeEnum.FINISHED:
          return 'var(--blue-0)';
        default:
          return 'var(--grey-0)';
      }
    },
    getMessageColor() {
      if (this.isAttendant || this.ifFinished) {
        return this.message.privateObservation ? 'var(--background)' : 'var(--background-1)';
      }

      return '';
    },
    deleteMessage() {
      if (this.$props.deleteMessageProp) {
        this.$props.deleteMessageProp(this.message);
      }
    },
    deleteAttachment(attachment, index) {
      if (this.$props.deleteAttachmentProp) {
        this.idAttachement = index.id;
        // eslint-disable-next-line vue/max-len
        this.$props.deleteAttachmentProp(this.message, index);
      }
    },
    allowedDeleteMessage() {
      if (!this.isAllowedDeleteMessage || this.message.privateObservation) {
        return false;
      }

      return this.allowedPermissionDelete();
    },
    allowedPermissionDelete() {
      if (this.message.type === MessageTypeEnum.ATTENDANT) {
        return isAdmin();
      }

      if (this.message.type === MessageTypeEnum.SELLER) {
        return !isAdmin();
      }

      return false;
    },
  },
  data: () => ({
    idAttachement: null,
  }),
});
</script>

<style lang="scss" scoped>
.finished-message {
  color: #02d13f;
}

p {
  margin: 0;
}

.attachment {
  border-top: #e6e6e6 1px solid;
  padding-top: 25px;
  padding-bottom: 9px;
  margin-top: 25px;
}

.layout-button {
  color: white;
  float: right;
}

.layout-button a v-icon {
  font-size: 20px;
  width: auto;
}

.message-box {
  text-align: justify;
  width: auto;
  flex-grow: 1;
}

.name-box {
  width: 15%;
}

.delete-button {
  position: absolute;
  top: 15px;
  right: 10px;
}

.authorization {
  color: var(--green-0);
}

.unauthorization {
  color: var(--red-0);
}

.delete-button .v-icon {
  color: var(--blue-0);
  font-size: 22px;
}

.font-color {
  color: var(--orange-0);
}
</style>
