<template>
  <v-row justify="center">
    <v-dialog
      v-model="show"
      width="640"
      :persistent="persistent"
    >
      <v-card>
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>{{ description }}</v-card-text>
        <v-card-text>
          <v-textarea
            :placeholder='placeHolder'
            v-model='textValue'
            :error="error"
            :error-messages="errorMessage"
            validate-on-blur
            filled
            dense
            light/>
        </v-card-text>
        <v-card-text>{{ question }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class='custom-button--light'
            text
            @click="cancel"
            outlined
          >
          CANCELAR
          </v-btn>
          <v-btn
            :disabled="textValue == null"
            class='custom-button--dark'
            text
            @click="accept"
          >
            {{ buttonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: 'Observe as informações que foram passadas',
    },
    question: {
      type: String,
      default: 'Deseja realmente excluir o item?',
    },
    buttonText: {
      type: String,
      default: 'Enviar',
    },
    show: {
      type: Boolean,
      required: false,
      default: false,
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.textValue = '';
    },
    accept() {
      this.$emit('accept', this.textValue);
      this.textValue = '';
    },
  },
  data: () => ({
    text: false,
    errorMessage: null,
    placeHolder: 'Informe claramente o motivo',
    textValue: null,
    error: null,
    focusOut: null,
  }),
};
</script>
