<template>
  <v-skeleton-loader :loading="loading" type="article, actions">
    <FormContent :selected="selected" class="pa-6">
      <DialogWarning
        :open="showDeleteMessageModal"
        message="Deseja mesmo excluir a mensagem do atendimento?"
        screenTitle="Todas as informações desta mensagem serão perdidas com esta ação."
        v-on:confirm="confirmDeleteMessage"
        v-on:cancel="cancelDeleteMessage"
        denyDialog="CANCELAR"
        acceptDialog="SIM, EXCLUIR MENSAGEM"
      />
      <DialogWarning
        :open="showDeleteAttachmentModal"
        message="Deseja mesmo exluir o anexo?"
        :screenTitle="
          isPrivateObservation
            ? 'Este anexo desta observação privada será perdido com esta ação.'
            : 'Este anexo desta mensagem será perdido com esta ação.'
        "
        v-on:confirm="confirmDeleteAttachment"
        v-on:cancel="cancelDeleteAttachment"
        denyDialog="CANCELAR"
        acceptDialog="SIM, EXCLUIR ANEXO"
      />
      <TimelineChat
        v-if="localMessages.length > 0"
        :messages="localMessages"
        :isAllowedDeleteMessage="isAllowedDeleteMessage()"
        :deleteMessageProp="deleteMessageProp"
        :deleteAttachmentProp="deleteAttachmentProp"
        :finished="isFinished"
        :isSellerView="isSellerView"
      />
      <div class="d-flex flex-column" v-if="!isFinished">
        <div class="custom-font-title mb-8">Nova mensagem</div>
        <v-form ref="card" v-model="card" class="d-flex">
          <label class="left-content">Mensagem*</label>
          <v-textarea
            class="right-content"
            placeholder="Digite"
            v-model="messageForm.text"
            :rules="[rules.required, rules.length(5000)]"
            counter="5000"
            filled
            dense
            light
          />
        </v-form>

        <Attachments :form="messageForm" id="attachmentsMessageSeller" @uploadFile="uploadFile" :isLoading="isLoading" />

        <div class="d-flex justify-end mb-8">
          <v-btn class="custom-button--light mr-4" @click="clearForm()" :disabled="!messageForm.text" outlined>LIMPAR</v-btn>
          <v-btn class="custom-button--dark" @click="sendMessageForm()" depressed :disabled="!card">ENVIAR</v-btn>
        </div>
        <div v-if="justificatory">
          <div class="custom-font-title mb-8">Autorização de atendimento do chamado</div>
          <div class="custom-font color--orange-0 mb-7 ">
            Para que seja finalizado, este chamado necessita que seja autorizada ou não a realização do atendimento. Clique abaixo para formalizar o
            parecer final.
          </div>

          <v-btn class="custom-button--dark mr-4 mt-4" @click="showAuthorizeModal()" :disabled="hasAuthorize" outlined v-show="sellerAuthRequired"
            >AUTORIZAÇÃO DO ATENDIMENTO...</v-btn
          >
        </div>
        <ConfirmDialog
          @close="authorizeModal = false"
          dialogType="warning"
          dialogWidth="640"
          :confirmDefaultButtons="false"
          :show-form="authorizeModal"
        >
          <template v-slot:message>
            <p>Certifique-se de que todas as questões deste</p>
            <p>atendimento tenham sido esclarecidas através das</p>
            <p>mensagens. Após a finalização do chamado, o envio de</p>
            <p>novas mensagens não será mais possível.</p>
            <br />
            <p>Você autoriza o atendimento desse chamado ?</p>
            <br />
          </template>
          <template v-slot:buttons>
            <v-btn outlined color="primary" @click="closeAuthorizeModal()" class="mr-2">CANCELAR</v-btn>
            <v-btn color="primary" dark @click="authorize()" class="mr-2">SIM, EU AUTORIZO</v-btn>
            <v-btn color="primary" dark @click="unauthorize()" class="mr-2">NÃO AUTORIZO</v-btn>
          </template>
        </ConfirmDialog>

        <JustificatoryModal
          :show="showJustificatoryModal"
          :description="description"
          :question="question"
          :buttonText="buttonText"
          persistent
          v-on:accept="accept"
          v-on:cancel="closeAuthorizeModal"
        />
      </div>
    </FormContent>
  </v-skeleton-loader>
</template>

<script>
import FormContent from '@/components/template/form/FormContent';
import createMessagesTicket, { deleteMessage, deleteAttachment, getMessagesTicket } from '@/service/ticket/tickets-service';
import notification from '@/service/notification';
import TimelineChat from '@/components/template/chat/TimelineChat';
import { TicketStatusEnum } from '@/enums/TicketStatusEnum';
import Attachments from '@/components/template/Attachments';
import { post } from '@/service/api';
import ConfirmDialog from '@/components/utils/dialog/ConfirmDialog';
import JustificatoryModal from '@/components/modal/JustificatoryModal';
import { MessageTypeEnum } from '@/enums/MessageTypeEnum';

import DialogWarning from '@/components/template/alert/DialogWarning';

const currentUser = JSON.parse(localStorage.getItem('current_user'));

export default {
  name: 'AttendanceForm',
  components: {
    FormContent,
    TimelineChat,
    Attachments,
    ConfirmDialog,
    JustificatoryModal,
    DialogWarning,
  },
  data: () => ({
    card: false,
    rules: {
      length: (len) => (v) => (v || '').length <= len || `Você excedeu o limite de caracteres disponíveis na mensagem ${len}`,
      required: (v) => !!v || 'Campo obrigatório',
    },
    isPrivateObservation: false,
    isSellerView: true,
    selected: false,
    isLoading: false,
    messageForm: {
      text: null,
      username: currentUser.username,
      type: MessageTypeEnum.SELLER,
      attachments: [],
      privateObservation: false,
    },
    justificatory: false,
    hasAuthorize: false,
    hasAuthorizeByAdmin: false,
    showJustificatoryModal: false,
    selectedAuthorizationType: MessageTypeEnum.UNSELECTED,
    authorizeModal: false,
    description: null,
    question: null,
    buttonText: null,
    showDeleteMessageModal: false,
    showDeleteAttachmentModal: false,
    messageToDelete: null,
    attachmentToDelete: null,
    localMessages: [],
  }),
  computed: {
    isFinished() {
      return this.form?.status === TicketStatusEnum.FINISHED;
    },
    messages() {
      return this.form?.messages ? this.form.messages : [];
    },
    sellerAuthRequired() {
      return this.form?.topic?.sellerAuthRequired;
    },
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Geral',
    },
    form: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validationRules: {
      type: Object,
      default: () => ({}),
    },
  },
  async mounted() {
    await this.getTicketMessages();
  },
  beforeUpdate() {
    this.hasAuthorize = this.form?.hasAuthorizationBySeller;
    this.justificatory = this.sellerAuthRequired;
  },
  methods: {
    async getTicketMessages() {
     try {
      this.localMessages = await getMessagesTicket(this.$route.params.id) || [];
     } catch (error) {
       this.localMessages = [];
      notification('Erro ao carregar as mensagens.', 'error');
      console.error('Error on getTicketMessages', error);
    }
    },
    clearForm() {
      this.messageForm.text = null;
      this.messageForm.attachments = [];
      this.messageForm.type = MessageTypeEnum.SELLER;
    },
    saveRemarkForm() {
      const resp = createMessagesTicket(this.$route.params.id, this.messageForm);
      this.clearForm();
      notification('Mensagem enviada com sucesso', 'success');
    },
    async sendMessageForm() {
      try {
        const resp = await createMessagesTicket(this.$route.params.id, this.messageForm);
        this.clearForm();

        this.messages.push(resp);
        this.localMessages.push(resp);

        notification('Mensagem enviada com sucesso.', 'success');
      } catch (error) {
        notification('Erro ao excluir a Mensagem.', 'error');
        console.error('Error on method sendMessageForm', error);
      }
    },
    async uploadFile() {
      this.isLoading = true;
      const file = document.getElementById('attachmentsMessageSeller').files[0];
      const formData = new FormData();

      if (file) {
        formData.append('file', file, file.name);
      }

      // eslint-disable-next-line no-await-in-loop
      post('upload-service/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          if (response.status === 200) {
            const attachment = {
              key: response.data.id,
              storageKey: response.data.id,
              name: response.data.fileName,
              url: response.data.folder + response.data.id,
            };
            this.messageForm.attachments.push(attachment);
          } else {
            notification(`Erro ao anexar o arquivo: ${response.response.data.message}`, 'error');
          }
        })
        .catch((error) => {
          notification('Erro ao anexar o arquivo', 'error');
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    showAuthorizeModal() {
      this.selectedAuthorizationType = MessageTypeEnum.UNSELECTED;
      this.authorizeModal = true;
    },
    showAuthorizeByAdminAsSellerModal() {
      this.selectedAuthorizationType = MessageTypeEnum.UNSELECTED;
      this.authorizeModal = true;
    },
    closeAuthorizeModal() {
      this.showJustificatoryModal = false;
      this.selectedAuthorizationType = MessageTypeEnum.UNSELECTED;
      this.authorizeModal = false;
    },
    authorize() {
      this.selectedAuthorizationType = MessageTypeEnum.AUTHORIZATION_SELLER;
      this.description = 'Descreva abaixo sua justificativa para a autorização do atendimento deste chamado:';
      this.question = 'Deseja mesmo autorizar o atendimento?';
      this.buttonText = 'AUTORIZAR ATENDIMENTO';
      this.showJustificatoryModal = true;
    },
    unauthorize() {
      this.selectedAuthorizationType = MessageTypeEnum.UNAUTHORIZATION_SELLER;
      this.description = 'Descreva abaixo sua justificativa para a não autorização do atendimento deste chamado:';
      this.question = 'Deseja mesmo não autorizar o atendimento?';
      this.buttonText = 'NÃO AUTORIZAR ATENDIMENTO';
      this.showJustificatoryModal = true;
    },

    accept(textValue) {
      this.messageForm.text = textValue;
      this.messageForm.type = this.selectedAuthorizationType;
      this.sendMessageForm();
      this.hasAuthorize = true;
      this.closeAuthorizeModal();
      this.clearForm();
      notification('Autorização enviada com sucesso.', 'success');
    },
    isAllowedDeleteMessage() {
      return !(this.form.status === TicketStatusEnum.CANCELLED || this.form.status === TicketStatusEnum.FINISHED);
    },
    deleteMessageProp(message) {
      this.messageToDelete = message;
      this.showDeleteMessageModal = true;
    },
    cancelDeleteMessage() {
      this.showDeleteMessageModal = false;
    },
    confirmDeleteMessage() {
      deleteMessage(this.form.id, this.messageToDelete.id).then((resp) => {
        if (resp.status === 200) {
          this.showDeleteMessageModal = false;
          const deletedMessage = this.localMessages.find((x) => x.id === this.messageToDelete.id);
          deletedMessage.text = 'Esta mensagem foi apagada';
          deletedMessage.type = MessageTypeEnum.EXCLUDED;
          notification('Mensagem excluída com sucesso.', 'success');
        } else {
          notification('Erro ao excluir a Mensagem.', 'error');
        }
      }).catch((err) => {
        notification('Erro ao excluir a Mensagem.', 'error');
        console.error('Error on confirmDeleteMessage', err);
      });
    },
    deleteAttachmentProp(message, indexAttachment) {
      const { attachments, privateObservation } = message;

      if (!message || typeof indexAttachment !== 'number') {
        return;
      }

      if (!attachments || attachments.length <= 0) {
        return;
      }

      this.attachmentToDelete = attachments[indexAttachment];
      this.isPrivateObservation = privateObservation;
      this.showDeleteAttachmentModal = true;
    },
    cancelDeleteAttachment() {
      this.showDeleteAttachmentModal = false;
    },
    confirmDeleteAttachment() {
      deleteAttachment(this.form.id, this.attachmentToDelete.id).then((resp) => {
        if (resp.status === 200) {
          this.showDeleteAttachmentModal = false;
          // eslint-disable-next-line vue/max-len,no-return-assign,array-callback-return
          this.form.messages.forEach((x) => x.attachments.find((y) => {
            if (y.id === this.attachmentToDelete.id) {
              this.attachmentToDelete.name = 'Este anexo foi excluído.';
              this.attachmentToDelete.isExcluded = true;
            }
          }));
          notification('Anexo Excluído.', 'success');
        } else {
          notification('Erro ao excluir anexo!', 'error');
        }
      });
    },
  },
};
</script>
