<template>
  <v-container class="box-content pa-0 pl-4 pr-4 pt-4" fluid>
    <FormStepController
      @formSubbmit="formSubbmit"
      :formModified="formModified"
      :form="form"
      :loading="loading"
      :editing="editing"
      :steps="steps"
      backAlertMessage="Deseja cancelar o cadastro deste atendimento?"
      alertScreenTitle="cadastro de atendimento"
      :title="!editing ? 'Cadastro de atendimento' : 'Edição de atendimento'"
      :disabledTabCount="editing ? 0 : 1"
      :showDeleteActionButton="false"
      :actualTab="actualTab"
    >
      <GeneralDataProductForm
        title="Informações Gerais"
        :form="form"
        :loading="loading"
        :validationRules="steps['GeneralInformationForm'].rules"
        @validateRequired="validateRequired"
        @selectedTopic="selectedTopic"
      />
      <AttendanceForm title="Atendimento" :form="form" :loading="loading" :validationRules="steps['AttendanceForm'].rules" :disabled="!editing" />
    </FormStepController>
  </v-container>
</template>

<script>
import FormStepController from '@/components/template/form/FormStepController';
import GeneralDataProductForm from './steps/GeneralInformationForm';
import AttendanceForm from './steps/AttendanceForm';
import FormStepMixin from '../../../components/template/form/mixins/FormStepMixin';
import { get, post, put } from '../../../service/api';
import { routerPush } from '@/service/kplaceRouter';
import notification from '@/service/notification';

export default {
  name: 'SellerForm',
  mixins: [FormStepMixin],
  data() {
    return {
      editing: false,
      loading: true,
      saveDialogAlert: false,
      formModified: false,
      actualTab: 0,
      steps: {
        GeneralInformationForm: {
          step: this.editing ? 2 : 1,
          status: {},
          disabled: false,
          required: {
            topic: true,
            orderId: false,
            description: true,
          },
          rules: {
            topic: (value) => !!value || 'Campo `Assunto` é Obrigatório.',
            description: (value) => {
              if (value && value.length > 255) {
                return 'Texto muito extenso';
              }
              return !!value || 'Campo `Descrição` é Obrigatório.';
            },
            orderId: () => {
            if (this.form?.topic && this.form?.topic.orderInfoRequired && !this.form.orderId) {
              return 'Campo `Pedido` é Obrigatório.';
            }
            if (this.form?.orderId && !this.form.order?.clientName) {
              return 'Pedido não encontrado';
            }
            if (this.form?.orderId && !this.isValidOrder(this.form?.orderId)) {
              return 'Pedido inválido';
            }
            return true;
            },

          },
        },
        AttendanceForm: {
          step: this.editing ? 1 : 2,
          status: {},
          required: {},
          disabled: !this.editing,
        },
      },
      form: {
        attachments: [],
        requester: null,
        status: 'Aguardando atendimento',
        topic: null,
        orderId: null,
        ourNumber: null,
        description: null,
        order: null,
      },
    };
  },
  components: {
    FormStepController,
    AttendanceForm,
    GeneralDataProductForm,
  },
  async mounted() {
    try {
      this.loading = true;

      await this.init();
      await this.initOrderAttendance();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async verifyFiles(form) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < form.attachments.length; i++) {
        const attachment = form.attachments[i];
        if (!attachment.key) {
          const formData = new FormData();
          formData.append('file', attachment, attachment.name);
          // eslint-disable-next-line no-await-in-loop
          const { data } = await post('upload-service/file/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          form.attachments[i] = {
            key: data.id,
            name: data.fileName,
            url: data.folder + data.id,
          };
        }
      }
    },
    async update(data) {
      this.saveDialogAlert = false;

      if (data.topic?.id) {
        data.topic = data.topic.id;
      }
      try {
        const response = await put(`ui-integrator/tickets/${this.$route.params.id}`, data);
        if (response.status >= 200 && response.status <= 300) {
          notification('Atendimento atualizado com sucesso.', 'success');
        } else if (response.data?.cause) {
          notification(JSON.parse(response.data.cause.split(']:')[1])[0].message, 'error');
        } else {
          notification(`${response.response.data.message}`, 'error');
        }
      } catch (e) {
        notification('Erro ao atualizar atendimento', 'error');
      }
    },
    async save(data) {
      try {
        if (data.attendant?.username) {
          data.attendant = data.attendant.username;
        }
        data.topic = data.topic.id;
        const response = await post('ui-integrator/tickets/create', data, {
          headers: {},
        });
        if (response.status >= 200 && response.status <= 300) {
          this.editing = true;
          this.steps.AttendanceForm.disabled = false;
          this.form = response.data;
          notification('Chamado criado com sucesso.', 'success');
          routerPush(`/attendance/forms/edit/${response.data.id}`);
          this.actualTab = 1;
        } else if (response.data?.cause) {
          notification(JSON.parse(response.data.cause.split(']:')[1])[0].message, 'error');
        } else {
          notification(`${response.response.data.message}`, 'error');
        }
      } catch (e) {
        notification('Erro ao criar atendimento', 'error');
      }
    },
    async formSubbmit() {
      const data = { ...this.form };
      await this.verifyFiles(data);
      if (this.editing) {
        await this.update(data);
      } else {
        await this.save(data);
      }
    },
    dialogDenial() {
      this.saveDialogAlert = false;
    },
    dialogConfirmation() {
      this.save();
    },
    async init() {
      if (!this.$route.params.id) return;
      this.editing = true;
      this.actualTab = 1;
      const { data } = await get(`help-assistance-management/tickets/${this.$route.params.id}`);
      this.form = data;

      if (data.seller) {
        const seller = await get(`ui-integrator/sellers/${data.seller.id}`);
        this.form.seller = seller.data;
      }
      this.steps.GeneralInformationForm.required.orderId = this.form.orderId || data.topic.orderInfoRequired;
      this.$forceUpdate();
    },
    initOrderAttendance() {
      const orderId = this.$route.params?.orderId;
      if (!orderId) return;
      this.form.orderId = orderId;
    },
    selectedTopic(topic) {
      this.steps.GeneralInformationForm.required.orderId = topic?.orderInfoRequired;
    },
    isValidOrder(orderId) {
      const regex = /^[a-z]\d{8}-\d{2}$/;
      return regex.test(orderId);
    },
  },
};
</script>

<style scoped></style>
